import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { S3Client, ListObjectsV2Command } from "@aws-sdk/client-s3";
import { Download, Copy, Link, X, Tag, Check } from 'lucide-react';
import logoImage from '../assets/images/brand/logo.png';
import logoCenterImage from '../assets/images/brand/logo-center.png';
import logoGallery from '../assets/images/brand/logo-gallery.png';
import { Upload } from 'lucide-react';

// Add DiceIcon component
const DiceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <rect x="2" y="2" width="20" height="20" rx="2" ry="2"/>
    <circle cx="7" cy="7" r="1.5"/>
    <circle cx="17" cy="7" r="1.5"/>
    <circle cx="7" cy="17" r="1.5"/>
    <circle cx="17" cy="17" r="1.5"/>
    <circle cx="12" cy="12" r="1.5"/>
  </svg>
);

// SVG Icons Components
const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="11" cy="11" r="8"/><line x1="21" y1="21" x2="16.65" y2="16.65"/>
  </svg>
);

const TrendingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"/><polyline points="17 6 23 6 23 12"/>
  </svg>
);

const ClockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="10"/><polyline points="12 6 12 12 16 14"/>
  </svg>
);


// S3 Client initialization
const s3Client = new S3Client({
    endpoint: "https://nyc3.digitaloceanspaces.com",
    region: "nyc3",
    credentials: {
      accessKeyId: import.meta.env.VITE_DO_SPACES_KEY,
      secretAccessKey: import.meta.env.VITE_DO_SPACES_SECRET
    }
  });


// Helper function
const parseFileNameAndTags = (filename) => {
  const nameWithoutExtension = filename.replace(/\.[^/.]+$/, '');
  const parts = nameWithoutExtension.split('||').map(part => part.trim());
  const displayTitle = parts[0];
  const tags = parts[1] ? parts[1].split('-').map(tag => tag.trim()) : [];
  return { displayTitle, tags };
};

// Custom hook for intersection observer
const useIntersectionObserver = (options = {}) => {
  const [entry, setEntry] = useState(null);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setEntry(entry);
    }, { threshold: 0.1, ...options });

    const element = elementRef.current;
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [options.threshold, options.root, options.rootMargin]);

  return [elementRef, entry?.isIntersecting];
};

// Optimized Image component with lazy loading
const OptimizedImage = ({ src, alt, onLoad, className }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(false);
  const [ref, isIntersecting] = useIntersectionObserver();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isIntersecting && !shouldLoad) {
      setShouldLoad(true);
    }
  }, [isIntersecting]);

  const handleLoad = () => {
    setIsLoaded(true);
    onLoad?.();
  };

  const handleError = () => {
    setError(true);
    console.error('Failed to load image:', src);
  };

  return (
    <div ref={ref} className="relative w-full h-full">
      {/* Placeholder/Loading state */}
      {!isLoaded && !error && (
        <div className="absolute inset-0 bg-gray-800 animate-pulse rounded-lg" />
      )}
      
      {/* Error state */}
      {error && (
        <div className="absolute inset-0 bg-gray-800 flex items-center justify-center text-gray-500 rounded-lg">
          <span className="text-sm">Failed to load</span>
        </div>
      )}

      {/* Actual image */}
      {shouldLoad && (
        <img
          src={src}
          alt={alt}
          className={`${className} transition-opacity duration-300 ${
            isLoaded ? 'opacity-100' : 'opacity-0'
          }`}
          onLoad={handleLoad}
          onError={handleError}
          loading="lazy"
        />
      )}
    </div>
  );
};

// ImageCard Component
const ImageCard = ({ image, onClick }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [ref, isIntersecting] = useIntersectionObserver();

  useEffect(() => {
    if (isIntersecting && !isVisible) {
      setIsVisible(true);
    }
  }, [isIntersecting]);

  return (
    <div
      ref={ref}
      className="flex flex-col bg-gray-800 rounded-lg overflow-hidden cursor-pointer hover:bg-gray-700 
                 transition-all duration-300 hover:scale-102 h-full shadow-md"
      onClick={onClick}
    >
      <div className="relative w-full pt-[100%]">
        <div className="absolute inset-0">
          {isVisible && (
            <OptimizedImage
              src={image.image}
              alt={image.displayTitle}
              className="w-full h-full object-cover"
            />
          )}
        </div>
      </div>
      <div className="p-1 md:p-3 flex flex-col justify-between flex-grow">
        <p className="text-xs md:text-sm text-white mb-1 md:mb-2 line-clamp-2 text-center">
          {image.displayTitle}
        </p>
        {image.tags.length > 0 && (
          <div className="flex flex-wrap gap-0.5 md:gap-1 justify-center mt-auto">
            {image.tags.map((tag, index) => (
              <span
                key={index}
                className="px-1 md:px-1.5 py-0.5 bg-green-900 text-green-400 rounded-full text-[8px] md:text-xs 
                         whitespace-nowrap flex items-center gap-0.5 transition-all duration-300 
                         hover:bg-green-800"
              >
                <Tag size={8} className="hidden md:inline" />
                {tag}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const LazyLoadWrapper = ({ children, onInView }) => {
    const elementRef = React.useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            onInView();
            observer.disconnect();
          }
        },
        { threshold: 0.1 }
      );
  
      if (elementRef.current) {
        observer.observe(elementRef.current);
      }
  
      return () => observer.disconnect();
    }, [onInView]);
  
    return <div ref={elementRef}>{children}</div>;
  };

const Notification = ({ message, type = 'success' }) => (
    <div className={`fixed top-4 right-4 z-50 p-4 rounded-lg shadow-lg transition-all transform 
      ${type === 'success' ? 'bg-green-900 text-green-400' : 'bg-red-900 text-red-400'}`}>
      <div className="flex items-center gap-2">
        {type === 'success' ? <Check size={20} /> : <X size={20} />}
        {message}
      </div>
    </div>
  );
 
  const ImageModal = ({ image, onClose }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [downloadStatus, setDownloadStatus] = useState('idle');
    const [copyImageStatus, setCopyImageStatus] = useState('idle');
    const [copyUrlStatus, setCopyUrlStatus] = useState('idle');
    const [notification, setNotification] = useState(null);
    const modalContentRef = useRef(null);
  
    const handleImageLoad = () => {
      setIsImageLoaded(true);
    };
  
    const showNotification = (message, type = 'success') => {
      setNotification({ message, type });
      setTimeout(() => setNotification(null), 2000);
    };
  
    const handleOverlayClick = (e) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    };
  
    const handleModalClick = (e) => {
      e.stopPropagation();
    };
  
    const handleDownload = async () => {
      try {
        setDownloadStatus('loading');
        const response = await fetch(image.image);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = image.displayTitle;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setDownloadStatus('success');
        showNotification('Download started!');
        setTimeout(() => setDownloadStatus('idle'), 2000);
      } catch (error) {
        setDownloadStatus('error');
        showNotification('Download failed', 'error');
        setTimeout(() => setDownloadStatus('idle'), 2000);
      }
    };
  
    const handleCopyImage = async () => {
      try {
        setCopyImageStatus('loading');
        const response = await fetch(image.image);
        const blob = await response.blob();
        
        try {
          await navigator.clipboard.write([
            new ClipboardItem({
              [blob.type]: blob
            })
          ]);
          setCopyImageStatus('success');
          showNotification('Image copied to clipboard!');
        } catch (clipboardError) {
          const img = new Image();
          img.crossOrigin = 'anonymous';
          
          img.onload = async () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            
            try {
              canvas.toBlob(async (blob) => {
                try {
                  await navigator.clipboard.write([
                    new ClipboardItem({
                      [blob.type]: blob
                    })
                  ]);
                  setCopyImageStatus('success');
                  showNotification('Image copied to clipboard!');
                } catch (err) {
                  throw new Error('Failed to copy image to clipboard');
                }
              }, 'image/png');
            } catch (canvasError) {
              throw new Error('Failed to process image');
            }
          };
          
          img.onerror = () => {
            throw new Error('Failed to load image');
          };
          
          img.src = image.image;
        }
      } catch (error) {
        console.error('Copy image error:', error);
        setCopyImageStatus('error');
        showNotification('Failed to copy image. Try using the download option instead.', 'error');
      } finally {
        setTimeout(() => setCopyImageStatus('idle'), 2000);
      }
    };
  
    const handleCopyUrl = () => {
      navigator.clipboard.writeText(image.image);
      setCopyUrlStatus('success');
      showNotification('URL copied to clipboard!');
      setTimeout(() => setCopyUrlStatus('idle'), 2000);
    };
  
    const getButtonClass = (status) => {
      const baseClass = "flex items-center gap-2 px-4 py-2 rounded transition-all duration-300 transform active:scale-95";
      switch (status) {
        case 'loading':
          return `${baseClass} bg-gray-600 cursor-wait`;
        case 'success':
          return `${baseClass} bg-green-600 text-white`;
        case 'error':
          return `${baseClass} bg-red-600 text-white`;
        default:
          return `${baseClass} hover:bg-opacity-80 hover:-translate-y-0.5`;
      }
    };
  
    useEffect(() => {
      const handleEscKey = (e) => {
        if (e.key === 'Escape') {
          onClose();
        }
      };
  
      window.addEventListener('keydown', handleEscKey);
      return () => window.removeEventListener('keydown', handleEscKey);
    }, [onClose]);
  
    return (
      <div 
        className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4"
        onClick={handleOverlayClick}
      >
        <div 
          ref={modalContentRef}
          className="bg-gray-800 rounded-lg w-full max-w-4xl max-h-[90vh] flex flex-col relative"
          onClick={handleModalClick}
        >
          {notification && (
            <Notification message={notification.message} type={notification.type} />
          )}
  
          {/* Header */}
          <div className="p-4 flex justify-between items-center border-b border-gray-700 flex-shrink-0">
            <h3 className="text-xl font-semibold truncate pr-4">{image.displayTitle}</h3>
            <button 
              onClick={onClose}
              className="text-gray-400 hover:text-white transition-colors p-2 hover:bg-gray-700 rounded-full"
            >
              <X size={24} />
            </button>
          </div>
  
          {/* Image Container */}
          <div className="flex-grow overflow-auto min-h-0 flex items-center justify-center p-4">
            <div className="relative flex items-center justify-center w-full h-full min-h-[300px]">
              {!isImageLoaded && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="w-12 h-12 border-4 border-green-600 border-t-transparent rounded-full animate-spin"></div>
                </div>
              )}
              <div className="relative max-w-full max-h-full flex items-center justify-center">
                <OptimizedImage
                  src={image.image}
                  alt={image.displayTitle}
                  onLoad={handleImageLoad}
                  className={`
                    max-w-full max-h-[calc(90vh-16rem)]
                    object-contain rounded-lg shadow-lg
                    transition-all duration-300
                    ${isImageLoaded ? 'opacity-100 scale-100' : 'opacity-0 scale-95'}
                  `}
                />
              </div>
            </div>
          </div>
  
          {/* Tags */}
          {image.tags.length > 0 && (
            <div className="px-4 py-2 border-t border-gray-700 flex-shrink-0">
              <div className="flex flex-wrap gap-2">
                {image.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-green-900 text-green-400 rounded-full transition-all duration-300
                             hover:bg-green-800 hover:scale-105 cursor-pointer flex items-center gap-1"
                  >
                    <Tag size={14} />
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          )}
  
          {/* Actions */}
          <div className="p-4 border-t border-gray-700 flex justify-center space-x-4 flex-shrink-0">
            <button
              onClick={handleDownload}
              className={`${getButtonClass(downloadStatus)} bg-green-700`}
              disabled={downloadStatus === 'loading'}
            >
              {downloadStatus === 'success' ? <Check size={20} /> : <Download size={20} />}
              {downloadStatus === 'loading' ? 'Downloading...' : 'Download'}
            </button>
  
            <button
              onClick={handleCopyImage}
              className={`${getButtonClass(copyImageStatus)} bg-gray-700`}
              disabled={copyImageStatus === 'loading'}
            >
              {copyImageStatus === 'success' ? <Check size={20} /> : <Copy size={20} />}
              {copyImageStatus === 'loading' ? 'Copying...' : 'Copy Image'}
            </button>
  
            <button
              onClick={handleCopyUrl}
              className={`${getButtonClass(copyUrlStatus)} bg-gray-700`}
            >
              {copyUrlStatus === 'success' ? <Check size={20} /> : <Link size={20} />}
              Copy URL
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ChevronLeft = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M15 18l-6-6 6-6"/>
    </svg>
  );
  
  const ChevronRight = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M9 18l6-6-6-6"/>
    </svg>
  );

const ImageGallery = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTag, setSelectedTag] = useState(null);
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [allTags, setAllTags] = useState(new Set());
    const [trendingTags, setTrendingTags] = useState([]);
    const [visibleRange, setVisibleRange] = useState({ start: 0, end: 20 });
    const [viewMode, setViewMode] = useState('search'); // 'trending', 'recent', or 'search'
    const searchInputRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);
    const [randomImages, setRandomImages] = useState([]);
    const [trendingFolderImages, setTrendingFolderImages] = useState([]);



      // Function to shuffle array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Update random images when view mode changes
  useEffect(() => {
    if (viewMode === 'random' && images.length > 0) {
      setRandomImages(shuffleArray([...images]));
    }
  }, [viewMode, images]);

    // Update visible range when scrolling
    useEffect(() => {
      if (viewMode === 'random') {
        const handleScroll = () => {
          if (
            window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1000 &&
            !isLoading
          ) {
            setVisibleRange(prev => ({
              start: prev.start,
              end: prev.end + 20
            }));
          }
        };
  
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }
    }, [viewMode, isLoading]);


    // Function to check scroll position and update arrow visibility
    const checkScroll = useCallback(() => {
        if (tagsContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = tagsContainerRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft + clientWidth < scrollWidth - 10); // 10px threshold
        }
    }, []);

    // Add scroll event listener
    useEffect(() => {
        const container = tagsContainerRef.current;
        if (container) {
            container.addEventListener('scroll', checkScroll);
            // Initial check
            checkScroll();
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', checkScroll);
            }
        };
    }, [checkScroll]);

    const TagsSection = () => {
      const [showLeftArrow, setShowLeftArrow] = useState(false);
      const [showRightArrow, setShowRightArrow] = useState(false);
      const tagsContainerRef = useRef(null);
  
      // Improved check scroll function with more precise scroll position check
      const checkScroll = useCallback(() => {
          if (tagsContainerRef.current) {
              const { scrollLeft, scrollWidth, clientWidth } = tagsContainerRef.current;
              // Only show left arrow if we've scrolled at least 10px
              setShowLeftArrow(scrollLeft >= 10);
              // Show right arrow if there's more content to scroll to
              setShowRightArrow(Math.ceil(scrollLeft + clientWidth) < scrollWidth - 10);
          }
      }, []);
  
      // Add scroll function
      const scrollTags = (direction) => {
          if (tagsContainerRef.current) {
              const container = tagsContainerRef.current;
              const scrollAmount = 200;
              const targetScroll = direction === 'right' 
                  ? container.scrollLeft + scrollAmount 
                  : Math.max(0, container.scrollLeft - scrollAmount);
              
              container.scrollTo({
                  left: targetScroll,
                  behavior: 'smooth'
              });
          }
      };
  
      // Initial visibility check and scroll/resize listeners
      useEffect(() => {
          const container = tagsContainerRef.current;
          
          // Initial check after a short delay to ensure content is rendered
          const initialCheckTimeout = setTimeout(() => {
              checkScroll();
          }, 100);
  
          // Check again after images and content are fully loaded
          window.addEventListener('load', checkScroll);
          
          // Add scroll event listener
          if (container) {
              container.addEventListener('scroll', checkScroll);
          }
  
          // Add resize listener
          window.addEventListener('resize', checkScroll);
  
          // Cleanup
          return () => {
              clearTimeout(initialCheckTimeout);
              window.removeEventListener('load', checkScroll);
              window.removeEventListener('resize', checkScroll);
              if (container) {
                  container.removeEventListener('scroll', checkScroll);
              }
          };
      }, [checkScroll]);
  
      // Force check when tags change
      useEffect(() => {
          const timer = setTimeout(() => {
              checkScroll();
          }, 100);
          return () => clearTimeout(timer);
      }, [allTags, checkScroll]);
  
      return (
          <div className="mb-8 relative">
              <div className="text-gray-400 mb-2 px-2">Tags:</div>
              <div className="relative group">
                  {/* Left Arrow */}
                  <div
                      className={`
                          hidden md:block absolute left-0 top-0 bottom-0 w-12
                          transition-opacity duration-300
                          ${showLeftArrow ? 'opacity-100' : 'opacity-0 pointer-events-none'}
                      `}
                      style={{
                          background: 'linear-gradient(to right, rgba(17, 24, 39, 0.9), rgba(17, 24, 39, 0))'
                      }}
                  >
                      <button
                          onClick={() => scrollTags('left')}
                          className={`
                              absolute left-1 top-1/2 -translate-y-1/2
                              flex items-center justify-center
                              w-8 h-8
                              bg-gray-800 
                              text-white
                              rounded-full
                              transition-all duration-300
                              hover:bg-green-700
                              z-[5]
                          `}
                      >
                          <ChevronLeft />
                      </button>
                  </div>
  
                  {/* Tags Container */}
                  <div 
                      ref={tagsContainerRef}
                      className="flex overflow-x-auto scrollbar-hide gap-2 px-2 py-1 snap-x scroll-smooth relative z-[1]"
                      style={{
                          scrollbarWidth: 'none',
                          msOverflowStyle: 'none',
                          WebkitOverflowScrolling: 'touch'
                      }}
                  >
                      {Array.from(allTags).map((tag) => (
                          <span
                              key={tag}
                              className={`
                                  flex-none
                                  px-3 py-1 
                                  rounded-full 
                                  cursor-pointer 
                                  transition-all 
                                  duration-300 
                                  flex 
                                  items-center 
                                  gap-1
                                  snap-start
                                  ${selectedTag === tag 
                                      ? 'bg-green-600 text-white scale-105 shadow-lg'
                                      : 'bg-green-900 text-green-400 hover:bg-green-800 hover:scale-105'
                                  }
                              `}
                              onClick={() => handleTagClick(tag)}
                          >
                              <Tag size={14} />
                              {tag}
                              {trendingTags.includes(tag) && (
                                  <TrendingIcon size={14} className="ml-1" />
                              )}
                          </span>
                      ))}
                  </div>
  
                  {/* Right Arrow */}
                  <div
                      className={`
                          hidden md:block absolute right-0 top-0 bottom-0 w-12
                          transition-opacity duration-300
                          ${showRightArrow ? 'opacity-100' : 'opacity-0 pointer-events-none'}
                      `}
                      style={{
                          background: 'linear-gradient(to left, rgba(17, 24, 39, 0.9), rgba(17, 24, 39, 0))'
                      }}
                  >
                      <button
                          onClick={() => scrollTags('right')}
                          className={`
                              absolute right-1 top-1/2 -translate-y-1/2
                              flex items-center justify-center
                              w-8 h-8
                              bg-gray-800
                              text-white
                              rounded-full
                              transition-all duration-300
                              hover:bg-green-700
                              z-[5]
                          `}
                      >
                          <ChevronRight />
                      </button>
                  </div>
              </div>
          </div>
      );
  };

    // Add ref for horizontal scrolling
    const tagsContainerRef = useRef(null);
    // Add smooth scroll function for tag navigation
    const scrollTags = (direction) => {
      if (tagsContainerRef.current) {
          const container = tagsContainerRef.current;
          const scrollAmount = 200; // Adjust scroll amount as needed
          container.scrollBy({
              left: direction === 'right' ? scrollAmount : -scrollAmount,
              behavior: 'smooth'
          });
      }
  };


// Update the getters for trending and recent images
const fetchAllImages = async () => {
  setIsLoading(true);
  setError(null);
  try {
    // Fetch images from root Telegram folder for recent uploads
    const telegramCommand = new ListObjectsV2Command({
      Bucket: "pkqxgntllmju",
      Prefix: "PEPE-NUTS/TELEGRAM/" // Empty prefix to get everything
    });

    const telegramResponse = await s3Client.send(telegramCommand);

    // Filter and process images from both folders
    const telegramImages = !telegramResponse.Contents ? [] : 
      telegramResponse.Contents
        .filter(item => {
          // Only include files with image extensions and exclude folders
          return item.Key.match(/\.(jpg|jpeg|png|gif)$/i) && 
                 !item.Key.endsWith('/') &&
                 !item.Key.includes('PEPE-NUTS/TRENDING/');
        })
        .map(item => {
          const fileName = item.Key.split('/').pop();
          const { displayTitle, tags } = parseFileNameAndTags(fileName);
          const imageUrl = `https://pkqxgntllmju.nyc3.digitaloceanspaces.com/${item.Key}`;
          
          return {
            id: item.Key,
            originalTitle: fileName,
            displayTitle,
            tags: tags.map(tag => tag.toLowerCase()),
            image: imageUrl,
            lastModified: item.LastModified,
            isTrending: false,
            source: 'telegram'
          };
        });

    // Fetch images from Telegram/trending folder
    const trendingCommand = new ListObjectsV2Command({
      Bucket: "pkqxgntllmju",
      Prefix: "PEPE-NUTS/TRENDING" // Updated path to trending folder inside Telegram
    });

    const trendingResponse = await s3Client.send(trendingCommand);

    // Filter and process trending images
    const trendingImages = !trendingResponse.Contents ? [] :
      trendingResponse.Contents
        .filter(item => {
          // Only include files with image extensions and exclude folders
          return item.Key.match(/\.(jpg|jpeg|png|gif)$/i) && !item.Key.endsWith('/');
        })
        .map(item => {
          const fileName = item.Key.split('/').pop();
          const { displayTitle, tags } = parseFileNameAndTags(fileName);
          const imageUrl = `https://pkqxgntllmju.nyc3.digitaloceanspaces.com/${item.Key}`;
          
          return {
            id: item.Key,
            originalTitle: fileName,
            displayTitle,
            tags: tags.map(tag => tag.toLowerCase()),
            image: imageUrl,
            lastModified: item.LastModified,
            isTrending: true,
            source: 'trending'
          };
        });

    // Debug logging
    console.log('Regular Telegram images found:', telegramImages.length);
    console.log('Trending images found:', trendingImages.length);
    if (trendingImages.length > 0) {
      console.log('Sample trending image URL:', trendingImages[0].image);
    }

    // Calculate tag frequencies from all images
    const allImages = [...telegramImages, ...trendingImages];
    const tagCount = {};
    allImages.forEach(img => {
      img.tags.forEach(tag => {
        tagCount[tag] = (tagCount[tag] || 0) + 1;
      });
    });

    // Get top 5 trending tags
    const trending = Object.entries(tagCount)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 5)
      .map(([tag]) => tag.toLowerCase());

    setTrendingTags(trending);
    setAllTags(new Set(Object.keys(tagCount)));
    setImages(allImages);
    setTrendingFolderImages(trendingImages);

    // If no images were found, set an error
    if (allImages.length === 0) {
      setError('No images found in the gallery');
    }
  } catch (err) {
    console.error('Error fetching images:', err);
    setError('Failed to load images. Please try again later.');
  } finally {
    setIsLoading(false);
  }
};

const searchResults = useMemo(() => {
  if (!searchQuery) return [];
  
  const query = searchQuery.toLowerCase().trim();
  return images.filter(image => {
    // Search in title
    const titleMatch = image.displayTitle.toLowerCase().includes(query);
    
    // Search in tags
    const tagMatch = image.tags.some(tag => 
      tag.toLowerCase().includes(query)
    );
    
    return titleMatch || tagMatch;
  });
}, [images, searchQuery]);

// Update the useMemo hooks for trending and recent images
const trendingImages = useMemo(() => {
  // Only return images that are marked as trending (from the trending folder)
  return images.filter(img => img.source === 'trending');
}, [images]);

const recentImages = useMemo(() => {
  // Only return non-trending images from the Telegram folder
  return images
    .filter(img => img.source === 'telegram')
    .sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));
}, [images]);
    

  
    const handleViewModeChange = (mode) => {
      setViewMode(mode);
      setVisibleRange({ start: 0, end: 20 });
      setSelectedTag(null);
      
      if (mode === 'search' && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    };
  
    const handleTagClick = useCallback((tag) => {
      setSelectedTag(prevTag => prevTag === tag ? null : tag);
      setVisibleRange({ start: 0, end: 20 });
    }, []);
  
    const handleSearch = useCallback((e) => {
      setSearchQuery(e.target.value);
      setVisibleRange({ start: 0, end: 20 });
      if (viewMode !== 'search') {
        setViewMode('search');
      }
    }, [viewMode]);
  
    useEffect(() => {
      fetchAllImages();
    }, []);
  
    const ContributeButton = () => {
        const [isHovered, setIsHovered] = useState(false);
      
        return (
          <a 
            href="https://t.me/NUTS_MEME_BOT" 
            target="_blank" 
            rel="noopener noreferrer"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`
              relative 
              inline-flex items-center gap-2
              px-5 py-2.5
              bg-green-700 
              hover:bg-green-600
              rounded-md
              font-medium
              transition-all duration-300
              group
              border border-green-600
              ${isHovered ? 'shadow-lg shadow-green-900/20' : ''}
            `}
          >
            <Upload 
              size={18} 
              className={`
                transition-transform duration-300
                ${isHovered ? '-translate-y-0.5' : ''}
              `}
            />
            <span className={`
              transition-transform duration-300
              ${isHovered ? 'translate-x-0.5' : ''}
            `}>
              Contribute
            </span>
      
            {/* Subtle highlight effect */}
            <div className={`
              absolute inset-0 
              rounded-md
              bg-gradient-to-r from-green-400/10 to-transparent
              transition-opacity duration-300
              ${isHovered ? 'opacity-100' : 'opacity-0'}
            `} />
          </a>
        );
      };
      return (
        <div className="min-h-screen bg-gray-900 text-white pb-20 md:pb-0 z-50">
            <nav className="bg-green-800 p-4 sticky top-0 z-10">
                <div className="container mx-auto px-4">
                    {/* Desktop layout - visible on md and up */}
                    <div className="hidden md:flex justify-between items-center">
                        {/* Left logo */}
                        <div>
                            <a 
                                href="https://pepenuts.xyz" 
                                
                                rel="noopener noreferrer"
                                className="hover:opacity-80 transition-opacity duration-300"
                            >
                                <img 
                                    src={logoImage}
                                    alt="Pepe Nuts Logo" 
                                    className="h-10 w-auto"
                                    width="150"
                                    height="50"
                                />
                            </a>
                        </div>

                        {/* Center logo */}
                        <div className="flex items-center justify-center">
                            <img 
                                src={logoCenterImage}
                                alt="Pepe Nuts" 
                                className="h-16 w-auto"
                                width="250"
                                height="70"
                            />
                        </div>

                        {/* Contribute button */}
                        <div>
                            <ContributeButton />
                        </div>
                    </div>

                    {/* Mobile layout - visible below md */}
                    <div className="flex md:hidden justify-between items-center">
                        {/* Logo on the left */}
                        <div>
                            <img 
                                src={logoGallery}
                                alt="Gallery Logo" 
                                className="h-16 w-auto"
                                width="120"
                                height="40"
                            />
                        </div>

                        {/* Contribute button on the right */}
                        <div>
                            <ContributeButton />
                        </div>
                    </div>
                </div>
            </nav>

        <div className="container mx-auto p-4">
        {/* Desktop buttons - Add Random button */}
        <div className="hidden md:flex flex-wrap justify-center gap-2 mb-8 px-2">
          <button 
            className={`px-4 py-2 rounded flex items-center gap-2 transition-colors min-w-[120px] justify-center
              ${viewMode === 'search' ? 'bg-green-700' : 'bg-gray-700 hover:bg-gray-600'}`}
            onClick={() => handleViewModeChange('search')}
          >
            <SearchIcon />
            SEARCH
          </button>
          
          <button 
            className={`px-4 py-2 rounded flex items-center gap-2 transition-colors min-w-[120px] justify-center
              ${viewMode === 'trending' ? 'bg-green-700' : 'bg-gray-700 hover:bg-gray-600'}`}
            onClick={() => handleViewModeChange('trending')}
          >
            <TrendingIcon />
            TRENDING
          </button>
          <button 
            className={`px-4 py-2 rounded flex items-center gap-2 transition-colors min-w-[120px] justify-center
              ${viewMode === 'random' ? 'bg-green-700' : 'bg-gray-700 hover:bg-gray-600'}`}
            onClick={() => handleViewModeChange('random')}
          >
            <DiceIcon />
            RANDOM
          </button>
          <button 
            className={`px-4 py-2 rounded flex items-center gap-2 transition-colors min-w-[120px] justify-center
              ${viewMode === 'recent' ? 'bg-green-700' : 'bg-gray-700 hover:bg-gray-600'}`}
            onClick={() => handleViewModeChange('recent')}
          >
            <ClockIcon />
            RECENT
          </button>

        </div>
      
            {/* Mobile navigation */}
            <div className="md:hidden fixed bottom-0 left-0 right-0 bg-gray-800 border-t border-gray-700 z-50">
              <div className="flex justify-around items-center h-16">
                <button 
                  className={`flex flex-col items-center justify-center w-full h-full text-xs py-1 transition-colors
                    ${viewMode === 'search' ? 'text-white bg-gray-700' : 'text-gray-400 hover:text-white hover:bg-gray-700'}`}
                  onClick={() => handleViewModeChange('search')}
                >
                  <SearchIcon />
                  <span className="mt-1">Search</span>
                </button>
              <button 
                className={`flex flex-col items-center justify-center w-full h-full text-xs py-1 transition-colors
                  ${viewMode === 'random' ? 'text-white bg-gray-700' : 'text-gray-400 hover:text-white hover:bg-gray-700'}`}
                onClick={() => handleViewModeChange('random')}
              >
              <DiceIcon />
              <span className="mt-1">Random</span>
            </button>
            
                <button 
                  className={`flex flex-col items-center justify-center w-full h-full text-xs py-1 transition-colors
                    ${viewMode === 'trending' ? 'text-white bg-gray-700' : 'text-gray-400 hover:text-white hover:bg-gray-700'}`}
                  onClick={() => handleViewModeChange('trending')}
                >
                  <TrendingIcon />
                  <span className="mt-1">Trending</span>
              </button>

                <button 
                  className={`flex flex-col items-center justify-center w-full h-full text-xs py-1 transition-colors
                    ${viewMode === 'recent' ? 'text-white bg-gray-700' : 'text-gray-400 hover:text-white hover:bg-gray-700'}`}
                  onClick={() => handleViewModeChange('recent')}
                >
                  <ClockIcon />
                  <span className="mt-1">Recent</span>
                </button>
              </div>
            </div>
      
            {/* Main content */}
            <div className="mb-8">
              {/* Search bar and tags - only visible in search view */}
              {viewMode === 'search' && (
                <>
                    <div className="mb-8 px-2">
                        <div className="relative max-w-full">
                            <input
                                ref={searchInputRef}
                                type="text"
                                placeholder="Search for images"
                                className="w-full p-4 rounded bg-gray-700 text-white focus:ring-2 focus:ring-green-500 transition-all"
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                            <div className="absolute right-4 top-4 text-gray-400">
                                <SearchIcon />
                            </div>
                        </div>
                    </div>

                    <TagsSection />
                </>
            )}
      
              {/* Content based on view mode */}
              {isLoading ? (
                <div className="flex justify-center items-center h-64">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
                </div>
              ) : error ? (
                <div className="bg-red-600 text-white p-4 rounded mb-4 animate-fade-in">
                  {error}
                </div>
              ) : (
                <>
        {viewMode === 'search' && (
          <>
            {(!searchQuery && !selectedTag) ? (
              // Show trending and recent sections
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
                {/* Trending section */}
                <div>
                  <h2 className="text-xl font-bold mb-2 md:mb-4 flex items-center gap-2">
                    <span className="text-green-500"><TrendingIcon /></span>
                    Trending Images
                  </h2>
                  <div className="grid grid-cols-2 gap-2 md:gap-4"> {/* Always 2 columns on mobile */}
                    {trendingImages.slice(0, 6).map((image) => (
                      <LazyLoadWrapper key={image.id}>
                        <ImageCard
                          image={image}
                          onClick={() => setSelectedImage(image)}
                          trendingTags={trendingTags}
                        />
                      </LazyLoadWrapper>
                    ))}
                  </div>
                </div>
      
                {/* Recent section */}
                <div>
                  <h2 className="text-xl font-bold mb-2 md:mb-4 flex items-center gap-2">
                    <span className="text-green-500"><ClockIcon /></span>
                    Recent Uploads
                  </h2>
                  <div className="grid grid-cols-2 gap-2 md:gap-4"> {/* Always 2 columns on mobile */}
                    {recentImages.slice(0, 6).map((image) => (
                      <LazyLoadWrapper key={image.id}>
                        <ImageCard
                          image={image}
                          onClick={() => setSelectedImage(image)}
                          trendingTags={trendingTags}
                        />
                      </LazyLoadWrapper>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
                       
                        // Show search results when searching or tag selected
                        <div>
                        <h2 className="text-xl font-bold mb-2 md:mb-4">
                          {searchQuery ? 'Search Results' : `Images tagged with "${selectedTag}"`}
                        </h2>
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 md:gap-4">
                          {(searchQuery ? searchResults : images.filter(img => img.tags.includes(selectedTag))).map((image) => (
                            <LazyLoadWrapper key={image.id}>
                              <ImageCard
                                image={image}
                                onClick={() => setSelectedImage(image)}
                                trendingTags={trendingTags}
                              />
                            </LazyLoadWrapper>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
      
      {viewMode === 'trending' && (
          <div className="px-2">
            <h2 className="text-xl font-bold mb-2 md:mb-4 flex items-center gap-2">
              <span className="text-green-500"><TrendingIcon /></span>
              Trending Images
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 md:gap-4">
              {trendingImages
                .slice(visibleRange.start, visibleRange.end)
                .map((image) => (
                  <LazyLoadWrapper key={image.id}>
                    <ImageCard
                      image={image}
                      onClick={() => setSelectedImage(image)}
                      trendingTags={trendingTags}
                    />
                  </LazyLoadWrapper>
                ))}
            </div>
          </div>
        )}
                {viewMode === 'random' && (
          <div className="px-2">
            <h2 className="text-xl font-bold mb-2 md:mb-4 flex items-center gap-2">
              <span className="text-green-500"><DiceIcon /></span>
              Random Images
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 md:gap-4">
              {randomImages
                .slice(visibleRange.start, visibleRange.end)
                .map((image) => (
                  <LazyLoadWrapper key={image.id}>
                    <ImageCard
                      image={image}
                      onClick={() => setSelectedImage(image)}
                      trendingTags={trendingTags}
                    />
                  </LazyLoadWrapper>
                ))}
            </div>
          </div>
        )}
      
      {viewMode === 'recent' && (
          <div className="px-2">
            <h2 className="text-xl font-bold mb-2 md:mb-4 flex items-center gap-2">
              <span className="text-green-500"><ClockIcon /></span>
              Recently Uploaded
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 md:gap-4">
              {recentImages
                .slice(visibleRange.start, visibleRange.end)
                .map((image) => (
                  <LazyLoadWrapper key={image.id}>
                    <ImageCard
                      image={image}
                      onClick={() => setSelectedImage(image)}
                      trendingTags={trendingTags}
                    />
                  </LazyLoadWrapper>
                ))}
            </div>
          </div>
        )}
        
      
                  {/* Load more trigger */}
                  {viewMode !== 'search' && visibleRange.end < 
                    (viewMode === 'trending' ? trendingImages.length : 
                     viewMode === 'recent' ? recentImages.length : 0) && (
                      
                    <div 
                      id="load-more-trigger" 
                      className="h-10 w-full"
                      onClick={() => setVisibleRange(prev => ({
                        start: prev.start,
                        end: prev.end + 20
                      }))}
                    />
                  )}
                </>
              )}
            </div>
          </div>
      
          {/* Image modal */}
          {selectedImage && (
            <ImageModal
              image={selectedImage}
              onClose={() => setSelectedImage(null)}
            />
          )}
        </div>
      );
};

export default ImageGallery;